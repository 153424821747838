var $j = jQuery.noConflict();
$j(function($) {

  // LAZY LOAD
  $('img.lazy').lazyload({
    skip_invisible: false,
    threshold: 400,
    failure_limit: Math.max($('img.lazy').length - 1, 0)
  });
  $(window).trigger('scroll');

  $(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function(e) {
    $(document).trigger("scroll");
  });

  /*------------------------------------*\
    Carousels
  \*------------------------------------*/
  $('.owl-carousel-products').owlCarousel({
    loop: false,
    dots: false,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>"
    ],
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      480: {
        items: 2,
        nav: true
      },
      768: {
        items: 2,
        nav: true
      },
      1000: {
        items: 3,
        nav: true
      },
      1200: {
        items: 4,
        nav: true
      }
    }
  });

  $('.owl-carousel-gallery').owlCarousel({
    loop: false,
    margin: 15,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>"
    ],
    responsiveClass: true,
    responsive: {
      0: {
        items: 3,
        nav: true
      },
      480: {
        items: 4,
        nav: true
      },
      768: {
        items: 3,
        nav: true
      },
      1000: {
        items: 3,
        nav: true
      },
      1200: {
        items: 4,
        nav: true
      }
    }
  });

  $('.slick-carousel').slick({
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 10,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      }
    }, {
      breakpoint: 1000,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }]
  });

  // SIDEBAR CATEGORY MENU ACTIVE CLASS
  $('.panel-collapse').on('show.bs.collapse', function() {
    $(this).prev('.panel-heading').find('.panel-title').removeClass("collapsed");
  });

  $('.panel-collapse').on('hide.bs.collapse', function() {
    $(this).prev('.panel-heading').find('.panel-title').addClass("collapsed");
  });

  (function() {
    $('.js--quantity > .js--clickable').click(function(ev) {
      ev.preventDefault();
      var $input = $(this).siblings('input[type="text"]');
      var number = parseInt($input.val(), 10);
      if (isNaN(number)) {
        number = 1;
      }
      if ($(this).hasClass('js--plus-one')) {
        $input.val(number + 1);
      } else {
        number = number < 2 ? 2 : number;
        $input.val(number - 1);
      }
    });
  })();

  // PRODUCT PRICE LABEL(Price from:) MARGIN
  $('select.super-attribute-select').change(function() {
    $('.product-price span.label').addClass('no-margin');
    if ($(this).val() === '') {
      $('.product-price span.label').removeClass('no-margin');
    }
  });

  // Tabs
  $(document).ready(function() {
    $(function() {
      $('.nav-tabs a').tab();
    });
  });

  // Go To Top
  $('.footer-go-up').click(function() {
    $('body,html').animate({
      scrollTop: 0
    }, 800);
    return false;
  });

  $(document).ready(function() {
    $('.cart-nav').click(function(event) {
      event.stopPropagation();
      $(".cart-nav .dropdown-menu").slideToggle();
    });
    $(".showup").on("click", function(event) {
      event.stopPropagation();
    });
  });

  // READ MORE SLIDE TO
  $('.short-description a[href^="#"]').click(function() {
    var target = $(this.hash);
    if (target.length === 0) {
      target = $('a[name="' + this.hash.substr(1) + '"]');
    }
    if (target.length === 0) {
      target = $('html');
    }
    $('html, body').animate({
      scrollTop: target.offset().top
    }, 500);
    return false;
  });

  // ADD CLASS TO TABLE IN PRODUCT DESCRIPTION
  $('.single-product-tabs .tab-pane table').addClass('table table-hover').css('width', '');

  // PRODUCT VIEW IMAGES
  $('.images .product .single-image a').each(function(i, obj) {
    if (i === 0) {
      $(obj).removeAttr('rel');
    }
    $(this).click(function(e) {
      e.preventDefault();

      var imgthumb = $(this).attr('main-image-thumb');
      $('.main-image img').attr('src', imgthumb);

      if (!$(obj).attr('rel')) {
        $(obj).attr('rel', 'product-gallery');
      }
      $(e.currentTarget).removeAttr('rel');

      var imgtitle = $(this).attr('title');
      $('.main-image a').attr('title', imgtitle);
      $('.main-image img').attr({
        alt: imgtitle,
        title: imgtitle
      });
    });
  });

  var initialIndex = 0;
  var imagesArray = function() {
    var array = [];
    var imagesLocation = '.main-image';
    if ($('.images').length) {
      imagesLocation = '.images .product .owl-item';
    }
    $(imagesLocation).each(function() {
      var arrayItem = {
        href: $(this).find('a').attr('href'),
        title: $(this).find('a').attr('title')
      };
      array.push(arrayItem);
      if ($(arrayItem).attr('href') === $('.main-image a').attr('href')) {
        initialIndex = $(this).index();
      }
    });
    return array;
  };

  var productImages = imagesArray();

  $('.images .product .owl-item').click(function() {
    initialIndex = $(this).index();
  });

  $('.main-image a').click(function(e) {
    e.preventDefault();
    $.swipebox(
      productImages, {
        initialIndexOnArray: initialIndex,
        hideBarsDelay: false,
        removeBarsOnMobile: false
      }
    );
  });

  // BLOG - ADD CLASS "ROW" TO <p> IF <img> INSIDE
  $('.blog-single__content p').has('img').addClass('row');

  // Swipebox class to images
  isImg = /(jpg|jpeg|png|gif)$/i;
  $('a:has(img)', '.blog-single__content').filter(function() {
    return isImg.test($(this).attr('href'));
  }).attr('rel', 'gallery-single').addClass('swipebox-gallery');

  // Swipebox gallery
  $(".swipebox-gallery").swipebox({
    hideBarsDelay: false,
    removeBarsOnMobile: false
  });

  // CHECKOUT - NEWSLETTER CHECKBOX
  $('input#newsletter-checkbox').change(function() {
    var userEmail = $('input[name="billing[email]"]').val();
    if ($(this).prop('checked') === true && userEmail) {
      $('.mc-signup-checkout input[name="EMAIL"]').val(userEmail);
      $('.newsletter-alert').hide();
    } else if ($(this).prop('checked') === true && !userEmail) {
      $('.newsletter-alert').show();
      $(this).prop('checked', false);
    }
  });

  // MAILCHIMP SUBMIT AND LANGUAGE
  window.fnames = new Array();
  window.ftypes = new Array();
  fnames[0] = 'EMAIL';
  ftypes[0] = 'email';
  /*
   * Translated default messages for the $ validation plugin.
   * Language: SL
   */
  $.extend($.validator.messages, {
    required: "To polje je obvezno.",
    remote: "Prosimo popravite to polje.",
    email: "Prosimo vnesite veljaven email naslov.",
    url: "Prosimo vnesite veljaven URL naslov.",
    date: "Prosimo vnesite veljaven datum.",
    dateISO: "Prosimo vnesite veljaven ISO datum.",
    number: "Prosimo vnesite veljavno število.",
    digits: "Prosimo vnesite samo števila.",
    creditcard: "Prosimo vnesite veljavno številko kreditne kartice.",
    equalTo: "Prosimo ponovno vnesite vrednost.",
    accept: "Prosimo vnesite vrednost z veljavno končnico.",
    maxlength: $.validator.format("Prosimo vnesite največ {0} znakov."),
    minlength: $.validator.format("Prosimo vnesite najmanj {0} znakov."),
    rangelength: $.validator.format("Prosimo vnesite najmanj {0} in največ {1} znakov."),
    range: $.validator.format("Prosimo vnesite vrednost med {0} in {1}."),
    max: $.validator.format("Prosimo vnesite vrednost manjše ali enako {0}."),
    min: $.validator.format("Prosimo vnesite vrednost večje ali enako {0}.")
  });

  // REMOVE STYLE ATTRIBUTE IN SIDEBAR - PROTOTYPE IS ACTING FUNNY
  (function() {
    var isBootstrapEvent = false;
    if (window.jQuery) {
      var all = jQuery('*');
      jQuery.each(['hide.bs.dropdown',
        'hide.bs.collapse',
        'hide.bs.modal',
        'hide.bs.tooltip',
        'hide.bs.tab',
        'hide.bs.popover'
      ], function(index, eventName) {
        all.on(eventName, function(event) {
          isBootstrapEvent = true;
        });
      });
    }
    var originalHide = Element.hide;
    Element.addMethods({
      hide: function(element) {
        if (isBootstrapEvent) {
          isBootstrapEvent = false;
          return element;
        }
        return originalHide(element);
      }
    });
  })();

});
